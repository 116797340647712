import { Component, ElementRef, Input, OnInit, ViewChild, inject, viewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from "ngx-toastr";
import { SwiperOptions } from "swiper";
import { fileFavoriteConfig } from "@app/pages/associations/shared/const/association-swiper.const";
import { SwiperComponent } from "swiper/angular";
import { GlobalFormDialog } from "@app/shared/dialogs/global-form/container/global-form-dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { GlobalFormDialogDataModel } from "@app/shared/dialogs/global-form/model";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { AssociationStore } from "@app/pages/associations/+data-access/association.store";
import { AuditLogDialogComponent } from "@app/shared/components/roam-layout/roam-log-activity/audit-log-dialog/audit-log-dialog.component";

@Component({
  selector: "app-association-summary",
  templateUrl: "./association-summary.component.html",
  styleUrls: ["./association-summary.component.scss"],
})
export class AssociationSummaryComponent {
  @Input() associationInfo: any;

  public accounts: any[] = [];
  public details: any = {};
  public policies: any = {};
  public services: any = {};

  public statistics: any = {};
  public logs: any[] = [];
  public bankAccounts = new MatTableDataSource<any[]>([]);
  public contacts = new MatTableDataSource<any[]>([]);
  public requests = new MatTableDataSource<any[]>([]);
  public meetings = new MatTableDataSource<any[]>([]);
  public violations = new MatTableDataSource<any[]>([]);
  public swiperConfig: SwiperOptions = fileFavoriteConfig;

  public displayedAccountColumns: string[] = [
    "name",
    "subtype",
    "number",
    "balance",
    "action",
  ];
  public displayedContactColumns: string[] = [
    "updatedAt",
    "contactTypeName",
    "description",
    "assignedUserName",
    "action",
  ];
  public displayedMeetingColumns: string[] = [
    "date",
    "attended",
    "note",
    "action",
  ];
  public displayedRequestColumns: string[] = [
    "createdAt",
    "unitName",
    "description",
    "statusName",
  ];
  public displayedViolationColumns: string[] = [
    "date",
    "unitName",
    "description",
    "statusName",
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  tabContent = viewChild<ElementRef>('tabContainer');

  #dialog = inject(MatDialog);
  store = inject(AssociationStore);

  ngOnChanges() {
    this.accounts = [];

    if (this.associationInfo.options) {
      for (const option of this.associationInfo.options) {
        if (option.key == "details") {
          for (const detail of option.values) {
            this.details[detail.key] = detail.value;
          }
        } else if (option.key == "policies") {
          for (const detail of option.values) {
            this.policies[detail.key] = detail.value;
          }
        } else if (option.key == "services") {
          for (const detail of option.values) {
            this.services[detail.key] = detail.value;
            this.store.updateServices(this.services);
          }
        }
      }
    }

    this.contacts.data = this.associationInfo.contacts;
    this.meetings.data = this.associationInfo.meetings;
    this.violations.data = this.associationInfo.violations;
    this.requests.data = this.associationInfo.requests;
    this.statistics.unitTypes = this.associationInfo.statistics;
    this.logs = this.associationInfo.logs;
    const bankAccountsData = [];
    if (this.associationInfo.accounts) {
      for (const act of this.associationInfo.accounts) {
        if (act.type == "Bank") {
          bankAccountsData.push(act);
        } else {
          this.accounts.push({
            id: act.id,
            type: act.type,
            name: act.name,
            balance: act.balance,
            change: 123,
          });
        }
      }
    }
    this.bankAccounts.data = bankAccountsData;
  }

  constructor(
    private toastr: ToastrService,
  ) {}

  public slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }

  public slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }

  public editAssociation(menu: string, data: any): void {
    this.#dialog.open(GlobalFormDialog, {
      ...dialogConfig.formEdit,
      data: {
        data,
        menu,
        module: "association",
      } as GlobalFormDialogDataModel,
    });
  }

  public actionDetail(): void {
    this.editAssociation("general", this.associationInfo);
  }

  deactiveAssociation() {
    this.#dialog.open(ConfirmationDialogComponent, {
      ...dialogConfig.confirmDialogCenter,
      data: {
        confirmation: {
          title: 'Deactive This Account',
          body: `<div class="fw-400 pt-8">Are you sure you want to deactive this account?</div>`,
          btnCancel: 'Cancel',
          btnSubmit: 'Okay'
        }
      }
    }).afterClosed()
    .subscribe()

    //
    // const message = `<div class="tc-black font-16px">Are you sure you want to deactive this account?</div>`;
    // this.#dialog
    //   .open(ConfirmDialogComponent, {
    //     backdropClass: "roam-dialog-backdrop",
    //     panelClass: "roam-dialog-panel",
    //     width: "700px",
    //     data: {
    //       title: "Deactive This Account",
    //       confirmLabel: "Okay",
    //       cancelLabel: "Cancel",
    //       message,
    //     },
    //   })
    //   .afterClosed()
    //   .subscribe(() => {
    //   });
  }

  openAuditLog() {
    this.#dialog.open(AuditLogDialogComponent, {
      ...dialogConfig.mdDialog
    })
  }
}
