import { Component, DestroyRef, ElementRef, inject, input, viewChild, ViewChild } from "@angular/core";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { fileFavoriteConfig } from "@app/pages/associations/shared/const/association-swiper.const";
import { ArrowNavigationModule } from "@app/shared/components/arrow-navigation/arrow-navigation.module";
import { FileConfigModule } from "@app/shared/components/file-config/file-config.module";
import { NgForOf, NgIf } from "@angular/common";
import { SwiperComponent, SwiperModule } from "swiper/angular";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { ToastrService } from "ngx-toastr";
import { ButtonActionComponent } from "@app/shared/components/button/button-action/button-action.component";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { AttachmentService } from "@app/shared/services/attachment.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FileUploaderConfig } from "@app/shared/components/file-uploader";
import { UserConfigStore } from "@app/core/user-config/+data-access";

@Component({
  selector: 'file-summary',
  standalone: true,
  imports: [
    RoamCardComponent, ArrowNavigationModule, FileConfigModule,
    NgForOf, NgIf, SwiperModule, ButtonManageComponent],
  template: `
    <app-roam-card [title]="'Files - Favorites'">

      <div actRef class="align-center gap-10">
        <button-manage icon="add" label="Upload" (onClick)="selectedFile()"/>
        
        <button-manage icon="switch" label="List View"/>
      </div>

      @if (documents().length > 0) {
        <div class="position-relative">
        <swiper #swiper [config]="swiperConfig">
          <ng-template swiperSlide *ngFor="let file of documents(); let file_i = index" class="swiper-card">
            <div class="card-wrappers">
              <app-file-favorite [fileName]="file.name"
                                 [isFavorite]="file.isFavorite"
                                 (onFavorite)="addToFavorite(file_i, $event)"
              ></app-file-favorite>
            </div>
          </ng-template>
        </swiper>
        <div class="swiper-nav prev" *ngIf="!isSlideBeginning">
          <app-round-nav-lg (onClick)="slidePrev()"></app-round-nav-lg>
        </div>
        <div class="swiper-nav next" *ngIf="!isSlideEnd">
          <app-round-nav-lg [left]="true"
                            (onClick)="slideNext()"></app-round-nav-lg>
        </div>
      </div>
      } @else {
        <div class="tc-grey font-14">
          File Not Available
        </div>
      }
    </app-roam-card>
  `,
  styles: ``
})
export class FileSummaryComponent {
  
  #destroyRef = inject(DestroyRef);
  documents = input<any[]>([]);
  toastr = inject(ToastrService);
  attachmentService = inject(AttachmentService);
  userConfig = inject(UserConfigStore);
  
  protected readonly swiperConfig = fileFavoriteConfig;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  fileInput = viewChild<ElementRef>('fileInput');


  get isSlideBeginning(): boolean {
    return this.swiper?.swiperRef?.isBeginning || false;
  }

  get isSlideEnd(): boolean {
    return this.swiper?.swiperRef?.isEnd || false;
  }

  public slideNext(){
    this.swiper?.swiperRef.slideNext(100);
  }

  public slidePrev(){
    this.swiper?.swiperRef.slidePrev(100);
  }

  public addToFavorite(index: number, isFavorite: boolean): void {
    setTimeout(() => {
      this.documents()[index].isFavorite = isFavorite;

      isFavorite
        ? this.toastr.success('Added to Favorites', '', {...ROAM_TOASTR.center})
        : this.toastr.show('Report has been removed from favorites', '', { ...ROAM_TOASTR.remove })

    }, 500)
  }


 selectedFile(): void {
  const input = document.createElement("input")!;
    const config: FileUploaderConfig = {
      model: "",
      modelId: "",
      propertyId: this.userConfig.signalSelectedAssociationId(),
    };

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const fileList = input.files;
      if (fileList) {
        const files = this.attachmentService.dedup([fileList[0]]);
        const payload = this.attachmentService.uploadFiles(files, config);
        this.attachmentService
        .sendAttachments(payload)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe({
          next: ({ progress, response }) => {
            console.log(response);
          },
          error: (err) => {
            console.error('Error occurred during upload:', err);  // Error handling
          }       
        });
      }
    }
 }

}
