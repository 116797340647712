import { Component, inject, input } from "@angular/core";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { BooleanPipe } from "@app/shared/pipes/boolean.pipe";
import { KeyValuePipe, NgForOf } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { MatDialog } from "@angular/material/dialog";
import { GlobalFormDialog } from "@app/shared/dialogs/global-form/container/global-form-dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { GlobalFormDialogDataModel } from "@app/shared/dialogs/global-form/model";
import { AssociationStore } from "@app/pages/associations/+data-access/association.store";
import { AssociationCollectionsComponent } from "../../../association-collections/association-collections.component";

@Component({
  selector: 'service-amenities',
  standalone: true,
  imports: [RoamCardComponent, BooleanPipe, KeyValuePipe, NgForOf, SharedModule],
  template: `
    <app-roam-card [title]="'Services & Amenities'">
      <div actRef>
        <button-manage icon="pencil-box" label="Edit"
          (onClick)="editServiceAmenities()"
        />
      </div>

      <div class="row no-gutter-content">
        <div *ngFor="let service of store.services() | keyvalue" class="col-4 pb-20px">
          <p class="summary__additional-title">{{ service.key | uncamel }}</p>
          <p class="summary__additional-label">{{ service.value | boolean }}</p>
        </div>
      </div>
    </app-roam-card>
  `
})
export class ServiceAmenitiesComponent {
  services = input<any>({});
  #dialog = inject(MatDialog);
  // store = inject(AssociationStore)

  constructor(
    public store : AssociationStore
  ) {

  }
  public editServiceAmenities() {
    this.#dialog.open(GlobalFormDialog, {
      ...dialogConfig.formEdit,
      data: {
        data: this.store.associationInfo(),
        menu: 'general',
        module: "association",
        tab: 'amenities',
      } as GlobalFormDialogDataModel,
    });
  }
}
