import { Component } from "@angular/core";

@Component({
  selector: "roam-log-point-info",
  standalone: true,
  imports: [],
  template: `
    <div>
      <span>AR 7199</span>
      <span>•</span>
      <span>Apply Late Fee</span>
    </div>
    <div>
      <span>Created :</span>
      <a class="link">David Gardner</a> on <span>04/14/2024</span>
    </div>
  `,
  styles: ``,
})
export class RoamLogPointInfoComponent {}
