import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RoamDialogMdcComponent } from '@app/shared/components/roam-dialog/roam-dialog-mdc/roam-dialog-mdc.component';
import { RoamLogPointInfoComponent } from '@app/shared/components/roam-log-point/roam-log-point-info/roam-log-point-info.component';

@Component({
  selector: 'audit-log-dialog',
  standalone: true,
  imports: [MatDialogModule, RoamDialogMdcComponent, RoamLogPointInfoComponent],
  template: `
    <app-roam-dialog-mdc [title]="'Audit Log'" [close]="true">
      <div dialogBody>
        
      </div>
    </app-roam-dialog-mdc>
  `,
  styles: ``
})
export class AuditLogDialogComponent {

}
